export enum DisplayType {
    TilesSmall = 1,
    TilesBig = 2,
    List = 3
}

export enum SortType {
    UploadDateAtoZ = 1,
    UploadDateZtoA = 2,
    CreateDateAtoZ = 3,
    CreateDateZtoA = 4,
}

export enum DuplicateAction {
    SaveNewest = 1,
    SaveBiggest = 2,
    SaveBoth = 3,
    Ignore = 4,
    Prompt = 5
}

export enum UploadToType {
    MainAndSubMenus = 1,
    OnlySubMenus = 2
}


export enum PermissionEnum {
    ViewCompany = "view-company",
    MutateCompany = "mutate-company",
    DeleteCompany = "delete-company",
    ViewCompanyUsers = "view-company-users",
    ManageRoles = "manage-roles",
    ManageMenu = "manage-menu",
    ManageUploadSettings = "manage-upload-settings",
    ManageDownloadSettings = "manage-download-settings",
    ManageDashboard = "manage-dashboard",
    ManageFileInfoSettings = "manage-file-info-settings",
    ManagePreferences = "manage-preferences-settings",
    ManageRegistrationFields = "manage-registration-fields",
    ManageFilters = "manage-filters",
    ManageTrash = "manage-trash",
    ManageListView = "manage-list-view",
    ManageWatermark = "manage-watermark-settings",
    ManageFileInfo = "manage-file-info",
    DeleteFile = "delete-file",
    ViewFileInfo = "view-file-info",
    ViewFileWithoutWatermark = "view-file-without-watermark",
    ShareOriginalFile = "share-original-file",
    DownloadOriginalFile = "download-original-file",
    UploadFile = "upload-file",
    UploadFilePublishedDirectly = "uploaded-file-published-directly",
    UploadFileToTempFolder = "uploaded-file-to-temp-folder",
    PublishFileFromTempFolder = "publish-file-from-temp-folder",
    MutateUser = "mutate-user",
    ViewQuitclaim = "view-quitclaim",
    DeleteUser = "delete-user",
    ExcludeCategory = "exclude-category",
    OnlyShareWithWatermarks = "only-share-with-watermark",
    OnlyDownloadWithWatermarks = "only-download-with-watermark",

    MutatePersonInfo = "mutate-person-information",
}
